.button {
    cursor: pointer;
    background-color: #006c60;
    color: #fff;
    padding: 15px;
    margin-top: 20px;
    font-size: 15px;
    margin-bottom: 20px;
    border: 1px solid;
  }

  #exportButton {
    cursor: pointer;
    background-color: #006c60;
    color: #fff;
    padding: 15px;
    margin-top: 20px;
    font-size: 15px;
    width: 20%;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  #exportButton:hover{
    background-color: #003c35;
    font-weight: bold;
  }

  #return {
    margin-top: 20px;
  }

  #goToTop {
    display: none;
    position: fixed;
    bottom: 15px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    color: #006c60;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    font-size: 4em;
    height: 105px;
  }

  #goToTop:hover {
    color: #003c35;
    font-weight: bold;
  }

  a {
    color: red;
    text-decoration: none;
    display: block;
  }
  
  .button:hover {
    background-color: #003c35;
    font-weight: bold;
  }

  .button:focus{
    outline: none;
  }
  
  .Disable {
    display: none;
  }

  .disabled {
    pointer-events: none;
  }

  .button.back {
    background-color: #b10000 !important;
  }
  
 .button.Logout {
    background-color: #b10000 !important;
    width: 100%;
    bottom: 0;
    margin: 0;
  }

#logout {
  background-color: #b10000 !important;
}
#logout:hover {
  background-color: #790000 !important;
}

.button.dashButton {
  /*  width: 100% !important;*/
    background-color: #9b9c9c !important;
    display: block;
    margin-top: 0px;
}

.button.button.dashButton:hover {
  background-color: #858686 !important;
}

.button.button.dashButton.button-active {
    font-weight: bold;
    background-color: #4e014e !important;
}

.action-navigation-container .button.actionBtn {
  display: inline-block !important;
  width: 45% !important;
  margin: 10px !important;
}

.button.dashButton.actionBtn {
  background-color: #006c60 !important;
  width: 20% !important;
}

button.button.dashButton.actionBtn.userBtn {
  width: 25% !important;
}

.button.dashButton.actionBtn:hover {
  background-color: #003c35 !important;
}

.button.dashButton.button.dashButton.actionBtn.UW {
  display: none;
}

.button.nav-selected, .button.button.dashButton.nav-selected:hover  {
  background-color: #003c35 !important;
}

.button.button.button-active {
    font-weight: bold;
    background-color: #4e014e;
}

.button.remove {
    background-color: #d5fb40 !important;
    color: #000;
    font-weight: bold;
    border: none;
}

.button.remove:hover{
    background-color: #b1d232 !important;
}

.forgot-password-form .button, .forgot-password-code-form .button {
  cursor: pointer;
  pointer-events: none;
  opacity: 0.4;
  background-color: #006c60;
  color: #fff;
  padding: 15px;
  margin-top: 20px;
  font-size: 15px;
  margin-bottom: 20px;
  border: 1px solid;
}

.forgot-password-form .button.button-valid, .forgot-password-code-form .button.button-valid {
  pointer-events: all;
  opacity: 1;
}

#exportLog {
  width: 25%;
}


.button.disabled {
  opacity: 0.5;
}
