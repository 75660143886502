body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

#portalShutDownMessage {
  display: none;
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
}

/* TYPOGRAPHY*/

.Header {
  text-align: center;
  background-color: #006c60;
  padding: 10px;
}

.Header h1 {
  font-size: 25px;
  color: #fff;
}

.Header svg {
  cursor: pointer;
  position: absolute;
  left: 20px;
  font-size: 30px;
  top: 20px;
  color: #fff;
}

/* LOGIN */

.LoginWrapper {
  border: solid 1px #99c4bf;
  background-color: #fff;
  width: 60%;
  margin: 0 auto;
  margin-top: 50px;
  padding-bottom: 20px;
}

.LoginWrapper .button {
  display: block;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  margin-bottom: 10px;
}

.LoginWrapper a {
  margin-top: 10px;
}

.errorText{
  color: red;
  font-weight: bold;
}

.disabled * {
  pointer-events: none;
}

button {
  display: block;
  margin: 0 auto;
  width: 50%;
  margin-bottom: 20px;
}

.header{
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
}

/* STRUCTURE */

.navigation-container{
  width: 100%;
  z-index: 1;
}

.navigation-container.policy-chosen {
 margin-top: 20px; 
}

#vehicle-section-policy-chosen #vehicle-table {
  height: 520px;
}

.navigation-container.sticky {
  top: 0;
}
.section.policy-stuff.sticky {
  position: fixed !important;
  top: 0;
  padding-top: 0px !important;
}

.section {
  background-color: #fff;
  width: 100%;
  text-align: center;
}

#vehicle-table {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 25px;
  height: 550px;
  overflow-y: scroll;
  text-transform: uppercase;
}

#vehicle-table-none {
  margin-bottom: 25px;
}

#section-policy{
  position: fixed;
  top: 0;
  z-index: 1
}

#section-policy span {
  display: inline;
  padding-right: 5px;
}

.policyDataWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

span {
  display: block;
}

#section-policy button {
  margin-bottom: 0px;
}

.row#topLine {
  margin-left: -145px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.row#topLine .property {
  width: 50%;
  text-align: right;
  padding-right: 60px;
}

.row#topLine .property:last-child{
  text-align: left !important;
  margin-right: -38px;
}

#policy-startDate {
  padding-right: 62px;
  margin-left: -61px;
}

#policy-vehicles {
  padding-left: 35px;
}

.property {
  margin-bottom: 5px;
  display: inline-block;

  text-align: center;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.action {
  float: left;
  text-align: center;
  background-color: #006c60;
  color: #fff;
  height: 150px;
  width: 15%;
  margin: 20px;
  cursor: pointer;
}
.action:hover {
  font-weight: bold;
}
.buttonSection {
  display: flex;
  justify-content: center;
}

.action span {
  line-height: 9.5;
}

/* VEHICLE TABLE */
.toggle-header {
  cursor: pointer;
  font-size: 0.8em;
}
.toggle-header h2 {
  display: inline;
}
.toggle-icon {
  color: #006c60;
  display: inline;
  margin: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table thead {
  background: #58b8d2;
  color: #fff;
  text-transform: capitalize;
}

table thead tr:hover {
  background: #58b8d2;
}

tr {
  cursor: pointer;
}

tr:hover {
  background-color: #ccc;
}


#main-view {text-align: center;}


/* FORM STYLES */

.FormField {
  width: 100%;
  clear: both;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.FormField.inline {
  width: 33%;
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 0px;
}

.FormField#registrationNumber-search {
  margin-top: 30px;
}

.FormField.FormField.inline label {
  display: inline-block;
  line-height: 2.3;
  margin-left: 5px;
}

label{
  display: block;
  font-weight: bold;
}

input {
  width: 45%;
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #bba;
}

input:focus{
  outline-color:#006c60;
}

#vehicle-section label {
  /*display: inline;*/
  padding-left: 5px;
}

.missing {
  border: 2px solid red !important;
}

.missing input, .missing select {
  border: 2px solid red;
}

.hint-text {
  font-style: italic;
  font-size: 0.9em;
  display: block;
  margin-left: 10px;
  font-weight: normal;
  margin-top: -10px;
  padding-right: 6px;
}


.policyNumber-field {
  text-transform: uppercase;
}

.errorBlock{
  display: block;
  color: red;
  margin: 5px;
}

.errorText{
  color: red;
  font-weight: bold;
  text-align: center;
  font-size: smaller;
}

.HelpText {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
}

.Success {
  z-index: 2;
  color: #fff;
  background-color: green;
  font-weight: bold;
  padding: 10px;
  position: absolute;
  width: 100%;
  top: 0;
}

.Error {
  color: #fff;
  background-color: red;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  position: absolute;
  width: 100%;
  top: 0;
}

.componentDisplayMessage {
  color: #fff;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.error {
  background-color: red;
  text-align: center;
}

.success {
  background-color: green;
}

select {
  width: 47%;
  padding: 10px;
  margin-top: 10px;
  height: 37px;
  background-color: #fff;
  border-color: #bba;
}

ul {
  list-style-type: none;
}

ul li {
  list-style-type: none;
  color: red;
  font-weight: bold;
}

.toggle-container {
  text-align: center;
  padding: 5px;
}

.toggle-container#topBar{
  
}

.close-icon {
  position: absolute;
  background-color: #006c60;
  border: none;
  color: white;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  width: auto;
  right: 20px;
}

.close-icon svg {
  font-size: small;
  padding-right: 5px;
}

.close-icon:hover{
  background-color: #003c35;
  font-weight: bold;
}

#close_vehicleSearch{
  margin-top: 20px
}

.close-icon.error{
  color: #fff;
  top: 10px;
}

#close_success {
  color: #fff !important;
  top: 20px;
}

.help-section h2 {
  cursor: pointer;
}

.help-section img {
  border: 3px solid #006c60;
  margin: 10px;
}

@media only screen and (min-width: 768px) {
  /* For mobile phones: */
 .Header {
   padding: 5px;
 }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #006c60;
}

input:focus + .slider {
  box-shadow: 0 0 1px #006c60;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* PAGINATION */
a.page-link {
  background: #006c60;
  margin: 10px;
  color: #fff;
}

a.page-link:hover{
  background-color: #003c35;
  font-weight: bold;
}


li.page-item.page-number.active a{
  background-color: #4e014e;
}

#numberOfVehicles{
  margin-top: -40px;
  margin-left: -20px;
}

#adminType label {
  margin-top: 25px;
  padding-right: 20px;
}

* {
  box-sizing: border-box;
}
#vehicle-section form input[type=text], #vehicle-section-policy-chosen form input[type=text], select, #amend-container input[type=text], #vehicle-section form [input=text], #create-admin input[type=select], #create-admin input[type=email],#create-admin input[type=password], #attachPin input[type=select] {
  width: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  float: left;
}

#vehicle-section form label, #vehicle-section-policy-chosen form label, #amend-container label, #create-admin label, #attachPin label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}


#vehicle-section form .col-50, #vehicle-section-policy-chosen form .col-50, #amend-container .col-50, #create-admin .col-50, #attachPin .col-50 {
  float: left;
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  text-align: right;
}

#vehicle-section form .col-75, #vehicle-section-policy-chosen form .col-75, #amend-container .col-75, #create-admin .col-75, #attachPin .col-75 {
  float: left;
  width: 30%;
  margin-top: 6px;
}

/* Clear floats after the columns */
#vehicle-section form .row:after, #vehicle-section-policy-chosen form .row:after, #amend-container .row:after, #create-admin .row:after, #attachPin .row:after {
  content: "";
  display: table;
  clear: both;
}
#amend-container .switch {
  float: left;
}
.read-only-text {
  text-align: left;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  #vehicle-section form .col-50, .col-75, input[type=submit], #vehicle-section-policy-chosen form .col-50, #amend-container .col-50, #create-admin .col-50, #attachPin .col-50 {
    width: 100%;
    margin-top: 0;
  }
}


input#make, input#model, input#registrationNumber, input#location-list, #create-admin input, #attachPin input, input#vehicleDerivative, input#namedDriver {
  width: 210px !important;
  float: left;
  text-transform: uppercase;
}

#email {
  text-transform: lowercase !important;
}

input#registrationNumbers, input#registrationNumber, #policyNumber-field {
  text-transform: uppercase !important;
}

#policyTable {
  width: 60%;
  margin: 0 auto;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

#policyTable td {
  padding-top: 10px;
}

#policyTable span {
  padding-left: 80px;
}
#exportText {
    width: 90%;
    font-size: 0.9em;
    margin: auto;
    margin-bottom: 10px;
    padding-top: 10px;
}

.importSuccess li {
  color: green !important;
}

#attachPin .read-only-text{
  text-align: left;
}

.warning {
  text-align: center;
  padding: 10px;
  background-color: lightyellow;
  font-weight: 500;
}

.systen.error {
  text-align: center;
}

.forgot-password-container {
  text-align: center;
  font-weight: 100;
}

.forgot-password-container a {
  color: initial
}

.password-icon {
  cursor: pointer;
  margin: 0;
  font-size: 2em;
  position: absolute;
  left: 250px;
  color: #006c60;
}

.password-info-icon {
  font-size: 90px;
}

.password-info-icon.success {
  background: none !important;
  color: #006c60;
}

.password-info-icon.not-sent {
  color: red;
}

#admin-form p {
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

.extra-action {
  cursor: pointer;
}
.empty-reg {
  display: none;
}

.valid-reg, .invalid-reg{
  display: block;
  margin-right: 100px;
  margin-top: 20px;
}

.valid-reg svg, .invalid-reg svg {
  font-size: 1.1em;
}

.valid-reg {
  color: green;
}

.invalid-reg {
  color: red;
}
.new-record {
  background-color: yellow;
}  

.create-password-code-form .codeContainer input{
  width: 5%;
  height: 50px;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.forgot-password-form img {
  margin-top: 10px;
  width: 50px;
}

.create-password-code-form input {
  margin-bottom: 15px;
}

.errorClick {
  color: #fff !important;
  text-decoration: underline;
  cursor: pointer;
}


/* The side navigation menu */
#sideNav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  background-color: #fff;
  color: #111;
  border-right: 2px solid #ddd;
  box-shadow: 0px 0px 2px 0px #ddd;
}

.sidebarSection {
  background-color: rgb(241, 241, 241);
  padding: 20px;
}

.optionsSwitcher {
  float: none;
  margin: 10px auto;
  padding: 5px;
  border-radius: 0px;
  border: 2px solid #000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1em;
}

.optionsSwitcher option {
  color: #006c60;
  padding: 5px;
  font-size: 1.1em;
  text-transform: uppercase;
}

#policySwitch span {
  text-align: center;
  font-size: 2em;
  color: #006c60;;
  cursor: pointer;
}

#policySwitch div {
  overflow-y: scroll;
  height: 150px;
  margin-top: -10px;
}

#policySwitch select {
  width: 100%;
  background: transparent;
  border: 2px solid#000;
  border-radius: 0px;
  font-weight: bold;
  font-size: 1.1em;
  padding: 5px;
  margin-top: -5px;
  margin-bottom: 10px;
}

#policySwitch select option {
  color: #006c60;
  background-color: rgb(241, 241, 241);
  padding: 10px;
  font-size: 1.2em;
  text-transform: uppercase;
}

#policySwitch button {
  margin-bottom: -25px;
}

.policyRow {
  cursor: pointer;
}

.policyRow.active {
  font-weight: bold;
}

.policyRow.active:after {
  position: relative;
  content: "\2713";
  color: #006c60;
  font-weight: bold;
  font-size: 1em;
  height: 20px;
  background: white;
  width: 20px;
  border-radius: 50px;
  text-align: center;
  line-height: 1.2;
  border: 1px solid;
  display: inline-block;
  margin-left: 10px;
}

#policySwitch div::-webkit-scrollbar {
  display: none;
}

.showMore {
  overflow-y: initial !important;
  height: auto !important;
}



.policyDetail {
  background-color: rgb(241, 241, 241);
  padding: 20px;
  margin-top: 0px;
}

/* The navigation menu links */
#sideNav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 40px;
  color: #111;
  display: block;
  transition: 0.3s;
  position: absolute;
  right: 0px;
}

#sideNav .close-icon{
  display: none;
}

#choice1 {
  border: 1px solid #111;
  width: 60%;
  padding: 5px;
  text-align: center;
}

.live{
  color: green;
}
.expired{
  color: red;
}
.renewal{
  color: orange;
}

/* When you mouse over the navigation links, change their color */

#open {
  color: #fff;
  cursor: pointer;
  position: absolute;
  left: 20px;
  font-size: 25px;
}


#sideNav #exportButton {
  width: 60%;
  font-size: 15px;
  padding: 15px;
  position: relative;
  text-align: center;
  color: #fff;
}

#sideNav #logout {
  width: 94% !important;
}

.untouchable {
  opacity: 0.7;
  pointer-events: none;
}
#sideNav
.closebtn {
  cursor: pointer;
  position: absolute;
  right: 20px;
  font-size: 40px;
}

#not-chosen-nav {
  margin-top: 60px;
}

.navigation-container.not-chosen-nav {
  margin-top: 60px;
}

.navigation-container.navigation-container.not-chosen-nav .button {
  display: block;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
}

#not-chosen-nav .button {
  display: block;
  width: 80% !important;
  margin: 0 auto;
  margin-bottom: 10px;
}
#attachPin {
  padding-bottom: 20px;
}

.policyImportResponse li{
  color: #111 !important;
}

#period-selector {
  margin-top: 75px;
}

label[for="midToggle"]{
  float: left;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 40%;
}

.policyDetail .switch {
  float: left;
  float: inline-end;
  margin-top: 15px;
}

.periodBox {
  cursor: pointer;
  border: 2px solid;
  width: 45%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  margin: 5px;
}

.periodBox.selected {
  background-color: #ddd !important;
}

.periodBox:hover {
  background-color: #ddd;
}
.panel {
  float: left;
  width: 50%;
}

.panel .error {
  width: 65%;
  margin: 0 auto;
}

#export #exportButton {
  clear: both;
  width: 25%;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 10px;
}

#tradePlateReadOnly-row {
  display: none;
}
#policyEdit .row .read-only-text {
  text-align: center;
}

#create-super-customer .read-only-text{
  text-align: center;
}

#policyEdit  span.editIcon {
    cursor: pointer;
    margin-left: 260px;
    margin-bottom: -18px;
}

#policyEdit #ppccChange input {
  width: 40%;
  text-align: center;
}

.changeField table tr {
  cursor: default;
}

.changeField table tr:hover {
  background-color: transparent;
}

.toggle-container #policyNumber, .toggle-container #tradePlate, .toggle-container #vehicleMake, .toggle-container #vehicleModel, .toggle-container #vehicleCoverStartDate, .toggle-container #vehicleCoverEndDate, .toggle-container #midStatus, .toggle-container #lastUpdated, .toggle-container #partyPolicyControlCount, .toggle-container #policyNumber, .toggle-container #midStatus, .toggle-container #recordType, .toggle-container #midFileSeqNo {
  display: none;
}

.componentDisplayMessage.success p:nth-child(2) {
  font-weight: bold;
  text-decoration: underline;
}

#edit-changes .success {
  margin-top: 20px;
}

.listOfPolTable {
  width: 30%;
  margin: 0 auto;
}

.listOfPolTable tr {
  cursor: default;
  background-color: transparent;
}

.listOfPolTable span {
  color: red;
  font-size: 1.1em;
  margin-top: 8px;
  margin-left: 65px;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.caps {
  text-transform: uppercase;
}